<template>
  <v-progress-linear
    background-color="grey lighten-1"
    color="green"
    buffer-value="100"
    rounded
    :value="value"
    height="50"
    dark
  >
    <div>{{ label + value + "%" }}</div>
  </v-progress-linear>
</template>


<script>
// import baseInput from "./WBaseInputMixin"; // this mixin helps handling some common mechanics
import { VProgressLinear } from "vuetify/lib";

export default {
  name: "w-percent-field",

  components: { VProgressLinear },

  props: {
    label: { type: String, default: "<unknown label>" },
    value: { type: Number, default: 0 },
  },

//   computed: {
//     appendIcon() {
//       if (this.goTo) {
//         return "mdi-step-backward";
//       }
//       return "";
//     },
//     getClasses() {
//       if (this.text) {
//         return "w-text-field mb-5";
//       }
//       return "w-text-field w-text-field-empty mb-5";
//     },
//   },

//   methods: {
//     openLink() {
//       if (this.goTo) {
//         if (this.goToRef) {
//           this.$router.push({
//             name: this.goTo,
//             params: { which: this.goToRef },
//           });
//         } else {
//           this.$router.push({ name: this.goTo });
//         }
//       }
//     },
//   },

//   watch: {
//     innerValue(newVal, oldVal) {
//       this.refreshComponent(newVal, oldVal);
//     },
//   },
};
</script>

<style scoped>
/* .w-text-field /deep/ .v-input__slot {
  height: 50px;
}
.w-text-field /deep/ .v-label {
  font-size: 20px;
}
.w-text-field-empty /deep/ .v-label {
  height: 25px;
  font-size: 16px;
  padding-top: 4px;
  padding-right: 2px;
  font-style: italic;
}
.w-text-field /deep/ .v-input__icon {
  margin-top: 4px;
} */
</style>