<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row>
      <v-col>
        <!-- ---------------------------------------------------------------------------------------------------------- ->
        <! all task fields                                                                                              ->
        <!- ------------------------------------------------------------------------------------------------------------->
        <v-row>
          <!-- -------------------------------------------------------------------------------------------------------- ->
          <! task identification                                                                                        ->
          <!- ----------------------------------------------------------------------------------------------------------->
          <v-col lg="3" md="6" sm="6" cols="12" order="first">
            <v-card outlined>
              <v-card-title>task instance</v-card-title>
              <v-row no-gutters>
                <v-col class="mx-4 px-0">
                  <w-text-field
                    :text="task.flow.reference"
                    label="parent flow"
                    :go-to="readFlowPath"
                    :go-to-ref="task.flow.reference"
                  />
                  <w-text-field :text="task.name" label="task name" />
                  <w-num-field :num="task.num" label="task number" />
                  <w-num-field
                    :num="task.intervalMin"
                    label="interval min."
                    v-if="task.taskConfig.intervals"
                  />
                  <w-num-field
                    :num="task.intervalMax"
                    label="interval max."
                    v-if="task.taskConfig.intervals"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <!-- -------------------------------------------------------------------------------------------------------- ->
          <! task config                                                                                                ->
          <!- ----------------------------------------------------------------------------------------------------------->
          <v-col lg="3" md="6" sm="6" cols="12">
            <v-card outlined>
              <v-card-title>task configuration</v-card-title>
              <v-row no-gutters>
                <v-col class="mx-4 px-0">
                  <w-text-field
                    :text="task.taskConfig.globalReference"
                    label="task config"
                    :go-to="readTaskConfPath"
                    :go-to-ref="task.taskConfig.globalReference"
                  />
                  <w-text-field :text="task.reference" label="task reference" />
                  <w-text-field :text="task.group.name" label="task group" />

                  <v-row>
                    <v-col sm="8" cols="8" class="mx-3 px-0">
                      <w-text-field
                        :text="task.functionName"
                        label="running function"
                      />
                    </v-col>
                    <v-col sm="2" cols="2" class="px-0">
                      <w-checkbox :value="task.manual" label="manual" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <!-- -------------------------------------------------------------------------------------------------------- ->
          <! task running                                                                                                ->
          <!- ----------------------------------------------------------------------------------------------------------->
          <v-col lg="6" md="6" sm="6" cols="12">
            <v-card outlined>
              <v-card-title>task running</v-card-title>
              <v-row no-gutters>
                <v-col class="mx-4 px-0">
                  <w-text-field :text="task.statusString" label="status" />
                  <w-text-field :text="task.startedAt" label="started" />
                </v-col>

                <v-col class="mx-4 px-0">
                  <w-text-field :text="task.updatedAt" label="updated" />
                  <w-percent-field
                    :value="task.currentProgress"
                    label="completed @ "
                  />
                </v-col>

                <v-col class="mx-4 px-0">
                  <w-text-field :text="task.finishedAt" label="finished" />
                  <w-text-field :text="task.totalElapsed" label="duration" />
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col><v-divider class="mx-4 pt-5"></v-divider></v-col>
              </v-row>

              <v-row no-gutters>
                <v-col sm="10" cols="8" class="mx-4 px-0">
                  <w-textarea :text="task.lastComment" label="last comment" />
                </v-col>
                <v-col sm="1" cols="2" class="mx-0 px-0">
                  <!-- run task -->
                  <v-tooltip top v-if="!pollingInterval">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ml-4 mb-5"
                        fab
                        dark
                        x-large
                        color="green"
                        v-bind="attrs"
                        v-on="on"
                        @click="runTask"
                      >
                        <v-icon dark>mdi-play</v-icon>
                      </v-btn>
                    </template>
                    <span>run manual task</span>
                  </v-tooltip>
                  <!-- stop polling (refresh) -->
                  <v-tooltip top v-if="pollingInterval">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ml-4 mb-5"
                        fab
                        dark
                        x-large
                        color="grey"
                        v-bind="attrs"
                        v-on="on"
                        @click="stopPolling"
                      >
                        <v-icon dark>mdi-stop</v-icon>
                      </v-btn>
                    </template>
                    <span>stop refreshing the view</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <!-- ------------------------------------------------------------------------------------------------------- -->
        </v-row>

        <!-- ---------------------------------------------------------------------------------------------------------- ->
        <! Tables                                                                                                        ->
        <!- ------------------------------------------------------------------------------------------------------------->
        <v-row>
          <v-col cols="6" sm="6">
            <v-card class="elevation-6">
              <w-entity-list
                :columns="taskColumns"
                :items="task.allDependsOn"
                :read-path="readTaskPath"
                read-ref="id"
                title="depends on"
              />
            </v-card>
          </v-col>
          <v-col cols="6" sm="6">
            <v-card class="elevation-6">
              <w-entity-list
                :columns="taskColumns"
                :items="task.allNextTasks"
                :read-path="readTaskPath"
                read-ref="id"
                title="next tasks"
              />
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12">
            <v-card class="elevation-6">
              <w-entity-list
                :columns="logColumns"
                :items="task.logs"
                title="Logs"
                sort-by="time,desc"
              />
            </v-card>
          </v-col>
        </v-row>

        <!-- --------------------------------------------------------------------------------------------------------- -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  VBtn,
  VCard,
  VCardTitle,
  VCol,
  VContainer,
  VDivider,
  VIcon,
  VRow,
  // VSpacer,
  VTooltip,
} from "vuetify/lib";
import endent from "endent";

import api from "../../components/external/modules/shared/utils/api";
import datehourformat from "../../components/external/modules/shared/utils/datehourformat";
import path from "../../shared/paths";
import WCheckbox from "../../components/external/modules/shared/views/WCheckbox.vue";
import WEntityList from "../../components/external/modules/shared/views/WEntityList.vue";
import WNumField from "../../components/external/modules/shared/views/WNumField.vue";
import WPercentField from "../../components/external/modules/shared/views/WPercentField.vue";
import WTextarea from "../../components/external/modules/shared/views/WTextarea.vue";
import WTextField from "../../components/external/modules/shared/views/WTextField.vue";

export default {
  components: {
    VBtn,
    VCard,
    VCardTitle,
    VCol,
    VContainer,
    VDivider,
    VIcon,
    VRow,
    // VSpacer,
    VTooltip,
    WCheckbox,
    WEntityList,
    WNumField,
    WPercentField,
    WTextarea,
    WTextField,
  },

  computed: {
    diagram: {
      get: function () {
        return this.mermaidContent;
      },
      set: function (val) {
        this.mermaidContent = endent`${val}`;
      },
    },
  },

  data: () => ({
    task: {
      flow: {},
      group: {},
      taskConfig: {},
      allDependsOn: [],
      allNextTasks: [],
    },

    logColumns: [
      { value: "time", text: "time" },
      { value: "progress", text: "progress %" },
      { value: "elapsedString", text: "elapsed" },
      { value: "comment", text: "comment" },
    ],

    taskColumns: [
      { value: "name", text: "name", groupable: false },
      { value: "statusString", text: "status", groupable: false },
      { value: "currentProgress", text: "progress", groupable: false },
      { value: "lastComment", text: "last comment", groupable: false },
    ],

    readFlowPath: path.TASKFLOW,
    readTaskConfPath: path.TASKCONFIG,
    readTaskPath: path.TASK,

    pollingInterval: "",
  }),

  created() {
    this.loadTask();
  },

  methods: {
    loadTask() {
      api
        .get("task/read/" + this.$route.params.which)
        .then((res) => {
          if (res.data.status === 200 && res.data.entity !== null) {
            // setting the main object
            this.task = res.data.entity;

            if (this.task.started) {
              this.task.startedAt = datehourformat(this.task.started, true);
            }
            if (this.task.updated) {
              this.task.updatedAt = datehourformat(this.task.updated, true);
            }
            this.task.progress = this.task.currentProgress + "%";
            if (this.task.finished) {
              this.task.finishedAt = datehourformat(this.task.finished, true);
            } else {
              this.task.progress =
                this.task.progress + " (eta: " + this.task.etaString + ")";
            }
            if (this.task.logs) {
              this.task.logs.forEach((log) => {
                log.time = datehourformat(log._creation, true, true);
              });
            } else {
              this.task.logs = [];
            }

            if (!this.task.allDependsOn) {
              this.task.allDependsOn = [];
            }
            if (!this.task.allNextTasks) {
              this.task.allNextTasks = [];
            }

            // making sure we stop polling for naught
            if (this.task.status !== 5) {
              this.stopPolling();
            } else {
              this.startPolling();
            }
          }
        })
        .catch((err) => {
          this.notify(err.response.data.messages);
        });
    },

    runTask() {
      api
        .get("task/run/" + this.$route.params.which)
        .then((res) => {
          if (res.data.status === 200 && res.data.entity !== null) {
            this.startPolling();
          }
          this.notify(res.data.messages);
        })
        .catch((err) => {
          this.notify(err.response.data.messages);
        });
    },

    startPolling() {
      // not doing this several times
      if (!this.pollingInterval) {
        this.pollingInterval = setInterval(() => {
          // if we're not viewing the flow anymore, which happens when we open a task for instance, then we make sure we stop the polling
          if (this.$route.name !== path.TASK) {
            this.stopPolling();
            return;
          }

          this.loadTask();
        }, this.task.flow.refreshViewEveryNum);
      }
    },

    stopPolling() {
      clearInterval(this.pollingInterval);
      this.pollingInterval = "";
    },

    // startPolling not doing it while it exists
    // and do it on the flow
  },
};
</script>

<style scoped>
.active-tab {
  background-color: #eef;
}
</style>
